/* eslint-disable no-shadow */
export interface ArgumentOutOfRangeError {
  argumentName: string;
  value?: unknown;
}

class ArgumentOutOfRangeErrorConstructor extends Error implements ArgumentOutOfRangeError {
  readonly argumentName: string;

  readonly value?: unknown;

  constructor(argumentName: string, value?: unknown, message?: string) {
    super(message ?? `Argument '${argumentName}' is invalid`);
    Object.setPrototypeOf(this, new.target.prototype);
    this.argumentName = argumentName;
    this.value = value;
  }
}

declare global {
  interface ArgumentOutOfRangeError extends Error {
    argumentName: string;
    value?: unknown;
  }
  interface ArgumentOutOfRangeErrorConstructor {
    new(argumentName?: string): ArgumentOutOfRangeError;
    (argumentName?: string): ArgumentOutOfRangeError;
    readonly prototype: ArgumentOutOfRangeError;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const ArgumentOutOfRangeError: ArgumentOutOfRangeErrorConstructor;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ArgumentOutOfRangeError = ArgumentOutOfRangeErrorConstructor;

export default ArgumentOutOfRangeError;
