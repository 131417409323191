import { VuetifyIcons } from "vuetify/types/services/icons";

const opIcons: Partial<VuetifyIcons> = {
  home: "fas fa-home",
  inquiry: "fa-solid fa-file-circle-question",
  clickIt: "fa-regular fa-hand-pointer",
  menu: "fas fa-bars",
};

export default opIcons;
