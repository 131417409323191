type ContentType = {
  oneLinner: string;
  isLeftToRight?: boolean;
  component?: "BusinessDescription" | "SplashButtonContent";
  paragraphs?: string[];
  props?: object;
};

export const homeViewContent: ContentType[] = [
  {
    oneLinner: "Automation That Drives Innovation",
    isLeftToRight: true,
    component: "SplashButtonContent",
    paragraphs: [
      "Unlock the full potential of your business with our comprehensive DevOps, DevSecOps, and CI/CD solutions. Streamline operations, enhance collaboration, and deliver secure, high-quality software faster than ever. Explore how our cutting-edge practices integrate automation, security, and agile methodologies to optimize your development pipeline, reduce time to market, and ensure robust application performance—all while keeping security at the forefront. Learn more on our dedicated page and see how we can transform your approach to development and operations.",
    ],
    props: {
      image: "./images/cloud001-sm.jpg",
      overlayCss: "blue-grey--text text--lighten-4 black--text-outline-medium",
      buttonText: "Smarter, Faster, Better",
      header: "DevOps, DevSecOps, IaaC",
      bodyText: "Revolutionizing Business Operations with DevOps Precision.",
      to: { name: "Discover DevOps" },
    },
  },
  {
    oneLinner: "Crafting, precise IT innovations",
    isLeftToRight: false,
    component: "SplashButtonContent",
    props: {
      image: "./images/image01-sm.jpg",
      overlayCss: "blue-grey--text text--lighten-4 black--text-outline-medium",
      buttonText: "Request a quote",
      header: "Business Solutions",
      bodyText: "Empowering customers through cloud solutions and services.",
      to: { name: "Contact Us" },
    },
  },
  {
    oneLinner: "Secure Identity and Cryptography Solutions",
    isLeftToRight: true,
    component: "SplashButtonContent",
    props: {
      image: "./images/image02-sm.jpg",
      overlayCss: "blue-grey--text text--lighten-4 black--text-outline-thin",
      buttonText: "Learn more",
      header: "Security Solutions",
      bodyText: "Advanced solutions for managing cryptographic keys and authentication systems.",
      to: { name: "Security Options", params: { form: "quote" } },
    },
  },
];

export default homeViewContent;
