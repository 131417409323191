<template>
  <v-container v-title="'Home'" class="HomeView ma-0 pa-0" fluid>
    <CompellingImagery001 />
    <v-container style="max-width: 1200px">
      <LazyItem
        v-for="(item, index) in content"
        :key="index"
        :direction="item.isLeftToRight ? 'left-to-right' : 'right-to-left'"
      >
        <template #one-linner>
          <v-card elevation="0">
            <v-card-title class="primary--text text--lighten-1 text-h3 no-word-breaks">{{
              item.oneLinner
            }}</v-card-title>
          </v-card>
        </template>
        <template #description>
          <component :is="item.component" v-bind="item.props" />
        </template>
      </LazyItem>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import homeViewContent from "./models/homeViewContent";

@Component({
  components: {
    BusinessDescription: () => import("@/components/BusinessDescription.vue"),
    SplashButtonContent: () => import("@/components/SplashButtonContent.vue"),
    LazyItem: () => import("@/components/LazyItem.vue"),
    CompellingImagery001: () => import("@/components/CompellingImagery001.vue"),
  },
})
export default class HomeView extends Vue {
  readonly content = homeViewContent;
}
</script>

<style scoped lang="scss">
::v-deep .v-btn__content {
  width: 100%;
}
</style>
