/* eslint-disable no-shadow */
export interface ArgumentError {
  argumentName: string;
}
class ArgumentErrorConstructor extends Error implements ArgumentError {
  readonly argumentName: string;

  constructor(argumentName: string, message?: string) {
    super(message ?? `Argument '${argumentName}' is invalid`);
    Object.setPrototypeOf(this, new.target.prototype);
    this.argumentName = argumentName;
  }
}

declare global {
  interface ArgumentError extends Error {
    argumentName: string;
  }
  interface ArgumentErrorConstructor {
    new(argumentName?: string, message?: string): ArgumentError;
    (argumentName?: string, message?: string): ArgumentError;
    readonly prototype: ArgumentError;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const ArgumentError: ArgumentErrorConstructor;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ArgumentError = ArgumentErrorConstructor;

export default ArgumentError;
