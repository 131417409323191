import Vue from "vue";
import Vuetify, { UserVuetifyPreset } from "vuetify";
import opIcons from "./opIcons";

Vue.use(Vuetify);

const config: UserVuetifyPreset = {
  icons: {
    iconfont: "fa",
    values: {
      ...opIcons,
    },
  },
  theme: {
    dark: false,
    options: {
      // Coupling Code (TAG: 7F533EEFE99240DE9FCFBE4980BDA681)
      cspNonce:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).cspNonce ?? "",
      customProperties: true,
    },
  },
};

export const vuetify = new Vuetify(config);
export default vuetify;
