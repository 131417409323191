/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-empty-interface */
export interface NotImplementedError { }

class NotImplementedErrorConstructor extends Error implements NotImplementedError {
  constructor(message?: string) {
    super(message ?? 'Not implemented');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

declare global {
  interface NotImplementedError extends Error { }
  interface NotImplementedErrorConstructor {
    new(message?: string): NotImplementedError;
    (message?: string): NotImplementedError;
    readonly prototype: NotImplementedError;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const NotImplementedError: NotImplementedErrorConstructor;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NotImplementedError = NotImplementedErrorConstructor;

export default NotImplementedError;
