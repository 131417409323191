<template>
  <v-app>
    <HeaderComponent style="visibility: visible" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import "vuetify/dist/vuetify.min.css";

@Component({
  components: {
    HeaderComponent: () => import("@/components/HeaderComponent.vue"),
  },
})
export default class HomeView extends Vue {}
</script>
