/* eslint-disable no-shadow */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InvalidOperationError { }

class InvalidOperationErrorConstructor extends Error implements InvalidOperationError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

declare global {
// eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface InvalidOperationError extends Error { }
  interface InvalidOperationErrorConstructor {
    new(message?: string): InvalidOperationError;
    (message?: string): InvalidOperationError;
    readonly prototype: InvalidOperationError;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const InvalidOperationError: InvalidOperationErrorConstructor;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const InvalidOperationError = InvalidOperationErrorConstructor;

export default InvalidOperationError;
